<template>
  <div class="box" style="background: linear-gradient(180deg, #00B5A0 0%, #0C9793 100%);">
    <div class="top-box">
          <div>
              <p>Hi，商家您好</p>
              <div class="riqi">
                  <img style="width:18px;height:18px;" src="https://middle-ground.shulanhealth.com/rili-icon%402x.png" alt="">
                  <span>今天是 {{toDay}}，天气{{weatherInfo.weather}}，气温{{weatherInfo.temperature}}°</span>
              </div>
          </div>
          <!-- <img class="head-sty" :src="'https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTL4Mluv3Vq2pwFZVfbeYUelic7xMjAheDCsQcvGVhG3lg6TpRrEZA7fQJzw2FduyWIGnBYFfbJq7nA/132'" alt=""> -->
      </div>
      <!-- <div class="dcx" v-if="myTx != null" @click="showPassengerInfo">你有1个行程</div> -->
    <div class="bar-box" style="margin-top:20px;">
      <div class="bar-item" @click="goTo" style="border-top-left-radius: 15px;">
        <img
          :src="'https://middle-ground.shulanhealth.com/jkgl/img-08.png'"
          alt="img"
        />
        <span>新增菜品</span>
      </div>
      <div class="bar-item" @click="goGkyy" style="border-top-right-radius: 15px;">
        <img
          :src="'https://middle-ground.shulanhealth.com/jkgl/img-10.png'"
          alt="img"
        />
        <span>查看预约</span>
      </div>
    </div>
    <div style="height:calc(100vh - 125px);background:#fff;overflow: auto;">
      <div class="item-box" v-for="item in caiList" :key="item.id">
        <img style="width:30%;border-radius: 5px;" :src="formatPicUrl(item.cPic)" alt="">
        <div style="margin-left:10px;width:70%;height: 70px;display: flex;flex-direction: column;justify-content: space-between;">
          <div class="cptitle">{{item.cName}}</div>
          <div class="cptitle"><van-tag type="warning">{{item.cPrice}}元/{{item.cDw}}</van-tag></div>
          <div style="display:flex;justify-content: flex-end;">
            <div class="cklx" @click="editTrip(item)" style="margin-right:7px;">编辑</div>
            <div class="cklx" @click="sxTrip(item)" :style="item.status == 1 ? 'color:red;border-color:red;' : ''">{{item.status == 1 ? '下架': '上架'}}</div>
          </div>
        </div>
      </div>
    </div>
    <loading :show="isShowLoading"></loading>
  </div>
</template>
<script>
import { Tag,Dialog,Toast } from "vant";
import dayjs from 'dayjs'
import AMap from "AMap"; // 引入高德地图
// import Empty from "./comm/empty.vue";
import Loading from "./comm/loading.vue";
import { passenger } from "@/api";
import Config from "@/config";
import Watermark from '@/utils/watermark.js'
export default {
  components: {
    // Empty: Empty,
    Loading: Loading,
    VanTag: Tag,
    // VanTreeSelect: TreeSelect,
  },
  data() {
    return {
      caiList:[],
      activeId: "",
      active: 0,
      opacitys: 0,
      opacitys1: 0,
      items: [
        { text: "上城区"},
        { text: "下城区"},
        { text: "江干区"},
        { text: "拱墅区"},
        { text: "西湖区"},
        { text: "滨江区"},
        { text: "萧山区"},
        { text: "余杭区"},
        { text: "临平区"},
        { text: "富阳区"},
        { text: "临安区"},
      ],
      isShowLoading: true,
      value: 0,
      switch1: false,
      switch2: false,
      option: [
        { text: "全部区域", value: 0 },
        { text: "西湖区", value: 1 },
        { text: "滨江区", value: 2 },
      ],
      sfcList: [],
      myInfoType: null,
      myInfo: null,
      toDay: "",
      weatherInfo: null,
      myTx: null
    };
  },

  mounted() {
    document.title = "工作台";
    Watermark.set("Say Hi 买菜")
    this.toDay = dayjs().format('YYYY年MM月DD日')
    this.myInfoType = JSON.parse(localStorage.getItem("user")).types
        this.myInfo = JSON.parse(localStorage.getItem("user"))
        this.getMyPassenger()
    //加载天气查询插件
    let _this = this
    AMap.plugin('AMap.Weather', function() {
        //创建天气查询实例
        var weather = new AMap.Weather();

        //执行实时天气信息查询
        weather.getLive('杭州市', function(err, data) {
            console.log(err, data);
            _this.weatherInfo = data
        });
    });
    // console.log(this.dayjs().startOf('date'))
  },
  methods: {
    getMyPassenger() {
      let params = {}
      passenger.getMyCaiFb(params).then(res => {
        if(res.data.code == 200) {
          this.caiList = res.data.data.data
        }
        this.isShowLoading = false
      })
    },
    formatPicUrl(e) {
      let ls = e.split(',')

      return Config.resource.base + ls[0]
    },
    formatCarNo(e) {
      const nBegin = e.substring(0,2)
      const nEnd = e.substring(e.length - 2, e.length)
      return nBegin + '***' + nEnd
    },
    goTo() {
      this.$router.push({
            name: 'add-cp',
            query: {
              isAdd: 'ADD'
            }
          })
    },
    goGkyy() {
      this.$router.push({
            name: 'sjckyy'
          })
    },
    goMy() {
      this.$router.push({
            name: 'my'
          })
    },
    searchXc() {
      this.getPassengerTripList();
    },
    showTrip(e) {
      this.$router.push({
        name: "trip-info",
        query: {
          id: e.id,
        },
      });
    },
    sxTrip(e) {
      Dialog.confirm({
      title: '提醒',
      message: e.status == 1 ? '确定下架该商品？' : '确定上架商品吗？'
    })
      .then(() => {
        let params = {
          id: e.id
        }
        passenger.sxCai(params).then(res=>{
          if(res.data.code == 200) {
            Toast.success(res.data.message)
            this.getMyPassenger()
          }
        })
      })
      .catch(() => {
        // on cancel
      });
    },
    editTrip(e) {
      this.$router.push({
        name: "add-cp",
        query: {
          info: JSON.stringify(e),
          isAdd: 'edit'
        },
      });
    },
    showPassengerInfo() {
      this.$router.push({
        name: "passenger-info"
      });
    },
    onConfirm() {
      this.$refs.item.toggle();
    },
    time2(data) {
      if (data) {
        if (data == this.dayjs().format("YYYY-MM-DD")) {
          return "今天";
        } else if (
          this.dayjs(data) > this.dayjs() &&
          this.dayjs(data) < this.dayjs().add(1, "day")
        ) {
          return "明天";
        } else {
          return this.dayjs(data).format("MM月DD日");
        }
      }
    },
    getPassengerTripList() {
      this.isShowLoading = true;
      let params = {
        page: 1,
        pageSize: 15,
        searchkey: this.items[this.active].text,
      };
      passenger.getPassengerTripList(params).then((res) => {
        this.sfcList = res.data.data;
        this.isShowLoading = false;
      });
    },
    getPassengerTripDistrictList() {
      this.isShowLoading = true;
      let params = {};
      passenger.getPassengerTripDistrictList(params).then((res) => {
        for(let i in res.data.data) {
          for(let j in this.items) {
            if(res.data.data[i].endDistrict.substring(6,9) == this.items[j].text) {
              this.items[j].badge = res.data.data[i].count
            }
          }
        }
        this.isShowLoading = false;
      });
    },
  },
};
</script>
<style scoped>
/deep/ .van-tree-select__nav {
  flex: 0.5;
}
/deep/ .van-info
{
  background: rgb(12, 151, 147);
}
/deep/ .van-sidebar-item--select::before {
  background-color: #42c6ff;
}
.item-box {
  display: flex;
  width: 90%;
  margin-left: 5%;
  height: 90px;
  border-bottom: 1px solid #f2f2f2;
  /* margin-bottom: 10px; */
  padding: 10px 0;
}
.cptitle {
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
}
.top-box {width: 90%;margin-left: 5%;display: flex;align-items: center;justify-content: space-between;padding-top: 21px;z-index: 2;}
.top-box div p {font-size: 16px;color: #FAFAFA;line-height: 0;}
.riqi {display: flex;align-items: center;}
.riqi img {margin-right: 5px;}
.riqi span {font-size: 13px;color: #FAFAFA;}
.head-sty {width: 44px;height: 44px;border-radius: 50%;}
.dcx {
  position: absolute;
  right: 0;
  bottom: 150px;
  z-index: 999;
  background: rgb(12, 151, 147);
  color: #fff;
  padding: 5px 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.wdxc {
  position: absolute;
  z-index: 9999;
  color: #fff;
}
.list-item {
  width: 92%;
  margin-left: 4%;
  background: #fff;
  box-shadow: 0px 2px 10px -4px rgba(2, 66, 58, 0.2);
  border-radius: 8px;
  padding: 15px 15px 10px 15px;
  margin-bottom: 15px;
}
.cklx {
  border: 1px solid #2d83e8;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: #2d83e8;
}
.bar-box {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}
.show-more {
  text-align: center;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: #2d83e8;
  margin-bottom: 10px;
  cursor: pointer;
}
.bar-item {
  flex: 1;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;
  background: #fff;
  justify-content: center;
}
.bar-item img {
  width: 20px;
  margin-right: 5px;
}
</style>
